import { ExportHistoryService } from '@amdb/drone/@core/services/export-history.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'export-history',
  templateUrl: './export-history.component.html',
  styleUrls: ['./export-history.component.scss']
})
export class ExportHistoryComponent implements OnInit {

  loading: boolean = false;

  constructor(private exportHistoryService: ExportHistoryService,
              private toastrService: NbToastrService) { }

  async ngOnInit(): Promise<void> {
  }
  
  async exportList() {
    try {
      this.loading = true;
      await this.exportHistoryService.downloadExportList();
    } catch (err) {
      if(err instanceof HttpErrorResponse) {
        if (err.error.errors) {
            this.toastrService.danger(err.error, 'Kļūda');
        } else {
            this.toastrService.danger('Kļūda eksportējot datņu sarakstu', 'Kļūda');
        }
      }
    } finally {
      this.loading = false;
    }
  }

  setLoading(loadingValue: boolean) {
    this.loading = loadingValue;
  }
}
