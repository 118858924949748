<nb-card [style.width.px]="700">
    <nb-card-header>
        <h5>Kontaktinformācijas izmaiņu saglabāšanas apstiprinājums</h5>
    </nb-card-header>
    <nb-card-body>
        <h6>Pēc izmaiņu apstiprināšanas tiks veikts zonu eksports. Eksporta laikā, zonām, kurās tiek izmantota labotā kontaktinformācija, spēkā stāšanās datums tiks atjaunots uz eksporta datumu un laiku</h6>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton
                hero
                status="success"
                (click)="submit()">Apstiprināt</button>
        <button nbButton
                class="ms-4"
                hero
                status="primary"
                (click)="dismiss()">Atcelt</button>
    </nb-card-footer>
</nb-card>
