import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Classifier } from '@amdb/drone/shared/models';
import { ClassifiersService } from '@amdb/drone/@core/services';
import { format } from 'date-fns';
import { SubmissionsService } from '../../../@core/services/submissions.service';

@Component({
    templateUrl: './status-change-dialog.component.html',
    styleUrls: ['./status-change-dialog.component.scss'],
})
export class StatusChangeDialogComponent implements OnInit {
    @Input()
    currentElementId: number;

    @Input()
    currentDataGroupId: number;

    @Input()
    currentCaaRestrictionAreaCode: string;

    @Input()
    currentCaaReasonNr: string;

    @Input()
    currentCaaReasonDate: Date;

    @Input()
    currentDataUpdateOnExportId: number;

    @Input()
    autoSubmittable: boolean;

    @Input()
    isAutoSubmitted: boolean;

    action: string;
    dataGroups: Classifier[];
    elements: Classifier[];
    yesNo: Classifier[];

    statusChange: {
        comment?: string;
        caaRestrictionAreaCode: string;
        caaReasonNr: string;
        caaReasonDate: Date;
        endDateTime: Date;
        dataUpdateOnExportId: number;
        elementId: number;
    } = {
        comment: null,
        caaRestrictionAreaCode: null,
        caaReasonDate: null,
        caaReasonNr: null,
        endDateTime: null,
        dataUpdateOnExportId: null,
        elementId: null,
    };

    @ViewChild('attachments', { static: false }) attachmentsSelector;

    constructor(
        protected ref: NbDialogRef<StatusChangeDialogComponent>,
        private toastrService: NbToastrService,
        private classifierService: ClassifiersService,
        private submissionsService: SubmissionsService,
    ) {}

    async ngOnInit() {
        this.elements = await this.classifierService.getElements();
        this.yesNo = await this.classifierService.getYesNo();
        if (this.action === 'approve-publish' && this.autoSubmittable) {
            var response = await this.submissionsService.getAutoSubmissionCaaRACode();
            this.currentCaaRestrictionAreaCode = response.caaRACode;
        }

        this.statusChange.elementId = this.currentElementId;
        this.statusChange.caaRestrictionAreaCode = this.currentCaaRestrictionAreaCode;
        this.statusChange.caaReasonNr = this.currentCaaReasonNr;
        this.statusChange.caaReasonDate = this.currentCaaReasonDate;
        this.statusChange.dataUpdateOnExportId = this.currentDataUpdateOnExportId;
    }

    submitStatusChange(): void {
        const attachments = this.getAttachmentsToUpload();
        const formData = new FormData();
        if (attachments) {
            for (let i = 0; i < attachments.length; i++) {
                const a = attachments[i];
                if (a.size > 1000000) {
                    this.toastrService.danger('Arhīva izmērs pārāk liels', 'Kļūda');
                    return;
                }
                formData.append('files', a);
            }
        }
        formData.append('comment', this.statusChange.comment || '');

        if (this.action === 'approve-import' && !this.autoSubmittable) {
            formData.append('caaRestrictionAreaCode', this.statusChange.caaRestrictionAreaCode);
            formData.append('caaReasonNr', this.statusChange.caaReasonNr);
            formData.append('caaReasonDate', format(this.statusChange.caaReasonDate, 'yyyy-MM-dd HH:mm:ss'));
            formData.append('elementId', this.statusChange.elementId.toString());
        } else if (this.action === 'approve-publish' && this.autoSubmittable) {
            formData.append('isAutoSubmitSubmission', this.autoSubmittable.toString());
            formData.append('caaRestrictionAreaCode', this.statusChange.caaRestrictionAreaCode);
        } else if (this.action === 'approve-publish' && !this.autoSubmittable) {
            formData.append('dataUpdateOnExport', this.statusChange.dataUpdateOnExportId.toString());
        } else if ((this.action == 'decline-publish' || this.action == 'decline-import') && this.autoSubmittable) {
            formData.append('isAutoSubmitSubmission', this.autoSubmittable.toString());
        } else if (this.action.startsWith('generate') && this.autoSubmittable) {
            formData.append('isAutoSubmitSubmission', this.autoSubmittable.toString());
        } else if (this.action.startsWith('edit')) {
            formData.append('caaRestrictionAreaCode', this.statusChange.caaRestrictionAreaCode);
            formData.append('caaReasonNr', this.statusChange.caaReasonNr);
            formData.append('caaReasonDate', format(this.statusChange.caaReasonDate, 'yyyy-MM-dd HH:mm:ss'));
            formData.append('elementId', this.statusChange.elementId.toString());
            formData.append('dataUpdateOnExport', this.statusChange.dataUpdateOnExportId.toString());
        }

        this.ref.close(formData);
    }

    private getAttachmentsToUpload(): File[] {
        const attachmentsBrowser = this.attachmentsSelector.nativeElement;
        if (!attachmentsBrowser.files || !attachmentsBrowser.files[0]) {
            return null;
        }

        return attachmentsBrowser.files;
    }

    dismiss() {
        this.ref.close();
    }
}
