<nb-card [nbSpinner]="loading">
    <nb-card-header>
        <h3 class="mb-4">UAS Ģeogrāfisko zonu JSON datņu arhīvs</h3>
        <div class="mb-3 row">
            <button class="btn btn-primary ms-2 fit"
                    type="button"
                    (click)="exportList()">Eksportēt datņu sarakstu (Excel)</button>
        </div>
    </nb-card-header>
    <nb-card-body>
        <amdb-export-history-list (onDownloadStart)="setLoading(true)"
                                    (onDownloadEnd)="setLoading(false)"
                                    (onDeleteStart)="setLoading(true)"
                                    (onDeleteEnd)="setLoading(false)"></amdb-export-history-list>
    </nb-card-body>
</nb-card>
