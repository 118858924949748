<nb-card [style.width.px]="700">
    <nb-card-header>
        <span *ngIf="action === 'submit'">Apstiprinu, ka iesniegtie dati ir pareizi un piekrītu to tālākai apstrādei</span>
        <span *ngIf="action !== 'submit'">Mainīt statusu</span>
    </nb-card-header>
    <nb-card-body>
        <div>
            <form (ngSubmit)="submitStatusChange()" #form="ngForm" aria-labelledby="title">
                <div *ngIf="action === 'approve-import' || action.startsWith('edit')">
                    <div class="row">
                        <div class="form-control-group mt-3 col-sm-6" *ngIf="elements && !autoSubmittable">
                            <label class="label">Gaisa telpas elements</label>
                            <nb-select
                                [(ngModel)]="statusChange.elementId"
                                #submissionElement="ngModel"
                                name="submissionElement"
                                fullWidth
                                [status]="submissionElement.touched ? (submissionElement.invalid ? 'danger' : 'success') : 'primary'"
                                [required]="true"
                                [attr.aria-invalid]="submissionElement.invalid && submissionElement.touched ? true : null"
                            >
                                <nb-option *ngFor="let element of elements" [value]="element.id">{{ element.name }}</nb-option>
                            </nb-select>
                            <ng-container *ngIf="submissionElement.invalid && submissionElement.touched">
                                <p class="error-message" *ngIf="submissionElement.errors?.required">
                                    Lauks ir obligāts!
                                </p>
                            </ng-container>
                        </div>

                        <div class="form-control-group mt-3 col-sm-6">
                            <label class="label" for="caaRestrictionAreaCode">UAS ģeogrāfiskās zonas nosaukums</label>
                            <input
                                nbInput
                                [(ngModel)]="statusChange.caaRestrictionAreaCode"
                                #caaRestrictionAreaCode="ngModel"
                                name="caaRestrictionAreaCode"
                                fullWidth
                                [status]="caaRestrictionAreaCode.touched ? (caaRestrictionAreaCode.invalid ? 'danger' : 'success') : 'primary'"
                                [required]="true"
                                [maxlength]="200"
                                [attr.aria-invalid]="caaRestrictionAreaCode.invalid && caaRestrictionAreaCode.touched ? true : null"
                            />
                            <ng-container *ngIf="caaRestrictionAreaCode.invalid && caaRestrictionAreaCode.touched">
                                <p class="error-message" *ngIf="caaRestrictionAreaCode.errors?.required">
                                    Lauks ir obligāts!
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row" *ngIf="!autoSubmittable">
                        <div class="form-control-group mt-3 col-sm-6">
                            <label class="label" for="caaReasonNr">CAA lēmuma Nr.</label>
                            <input
                                nbInput
                                [(ngModel)]="statusChange.caaReasonNr"
                                #caaReasonNr="ngModel"
                                name="caaReasonNr"
                                fullWidth
                                [status]="caaReasonNr.touched ? (caaReasonNr.invalid ? 'danger' : 'success') : 'primary'"
                                [required]="!isAutoSubmitted"
                                [maxlength]="30"
                                [attr.aria-invalid]="caaReasonNr.invalid && caaReasonNr.touched ? true : null"
                            />
                            <ng-container *ngIf="caaReasonNr.invalid && caaReasonNr.touched">
                                <p class="error-message" *ngIf="caaReasonNr.errors?.required">
                                    Lauks ir obligāts!
                                </p>
                            </ng-container>
                        </div>

                        <div class="form-control-group mt-3 col-sm-6">
                            <label class="label" for="caaReasonDate">CAA lēmuma datums</label>
                            <input
                                nbInput
                                [(ngModel)]="statusChange.caaReasonDate"
                                #caaReasonDate="ngModel"
                                name="caaReasonDate"
                                fullWidth
                                [status]="caaReasonDate.touched ? (caaReasonDate.invalid ? 'danger' : 'success') : 'primary'"
                                [required]="true"
                                [maxlength]="30"
                                [nbDatepicker]="pickerReasonDate"
                                [attr.aria-invalid]="caaReasonDate.invalid && caaReasonDate.touched ? true : null"
                            />
                            <nb-datepicker #pickerReasonDate format="yyyy-MM-dd HH:mm:ss"></nb-datepicker>
                            <ng-container *ngIf="caaReasonDate.invalid && caaReasonDate.touched">
                                <p class="error-message" *ngIf="caaReasonDate.errors?.required">
                                    Lauks ir obligāts!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="comment">Komentārs</label>
                    <textarea
                        nbInput
                        [(ngModel)]="statusChange.comment"
                        #comment="ngModel"
                        name="comment"
                        fullWidth
                        [status]="comment.touched ? (comment.invalid ? 'danger' : 'success') : 'primary'"
                        [maxlength]="1000"
                        [attr.aria-invalid]="comment.invalid && comment.touched ? true : null"
                    >
                    </textarea>
                </div>

                <label class="label" for="attachments">Pielikumi</label>
                <input class="ms-4" #attachments type="file" multiple />
                <button *ngIf="action === 'submit'" nbButton fullWidth status="success" class="mt-3" [disabled]="!form.valid" type="submit">
                    APSTIPRINU datu un metadatu patiesumu un iesniedzu tos.
                </button>
                <button *ngIf="action !== 'submit'" nbButton fullWidth status="success" class="mt-3" [disabled]="!form.valid" type="submit">
                    Saglabāt
                </button>
            </form>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Aizvērt</button>
    </nb-card-footer>
</nb-card>
