import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportHistoryService {

  constructor(private httpClient: HttpClient) { }

  exportJson(manualExport: boolean = false): Promise<void> {
    return this.httpClient.get<void>(`/api/v1/export-history/export/${manualExport}`).toPromise();
  }

  downloadReportById(reportId: number): Promise<void> {
    return this.httpClient.get<void>(`/api/v1/export-history/${reportId}`,
        { responseType: 'blob' as 'json', observe: 'response'}).toPromise()
        .then(response => {
          try {
            const contentDisposition = response.headers.get('content-disposition');
            const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            saveAs(response.body, fileName.replace(/^\"+|\"+$/g, ''));
          } catch {
            console.error('Couldn\'t save	file');
          }
        });
  }

  downloadExportList(): Promise<void> {
    return this.httpClient.get('/api/v1/export-history/history-list-export',
        { responseType: 'blob' as 'json', observe: 'response' }).toPromise()
        .then(response => {
          try {
            const contentDisposition = response.headers.get('content-disposition');
            const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            saveAs(response.body, fileName.replace(/^\"+|\"+$/g, ''));
          } catch {
            console.error('Couldn\'t save	file');
          }
        });
  }

  deleteExport(exportId: number): Promise<void> {
    return this.httpClient.delete<void>(`/api/v1/export-history/${exportId}`).toPromise();
  }
}
