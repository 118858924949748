import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService, NbMenuItem, NbDialogService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '@env/environment';
import { HelpDialogComponent } from '@amdb/drone/shared/components';
const { version: appVersion } = require('../../../../../package.json');

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

    @Input() position = 'normal';

    userMenu: NbMenuItem[] = [];
    user: any;
    appVersion: string;
    env = environment;

    constructor(private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private authService: NbAuthService,
        private dialogService: NbDialogService) {
        this.appVersion = appVersion;
    }

    ngOnInit() {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid()) {
                    this.user = token.getPayload();
                }
            });
        this.userMenu.push({ title: 'Profils', link: '/user-profile' });
        this.userMenu.push({ title: 'Izlogoties', link: '/auth/logout' });
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');

        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
    }

    async openHelp(): Promise<void> {
        await this.dialogService.open(HelpDialogComponent).onClose.toPromise();
    }
}
