import { Component } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'amdb-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent {

  //languages: { code: string, label: string }[] = environment.localization.availableLanguages;
  selectedLanguage: string;

  constructor() {
    let lang = localStorage.getItem('lang');
    if (!lang || lang === 'null') {
      //lang = environment.localization.defaultLocale;
      localStorage.setItem('lang', lang);
    }

    this.selectedLanguage = lang;
  }

  get currentLanguage(): string | null {
    //const language = this.languages.find(l => l.code === this.selectedLanguage);
    // if (language == null) {
    //   return null;
    // }

    // return language.code;
    return null;
  }

  changeLanguage(code: string): void {
    this.selectedLanguage = code;
    localStorage.setItem('lang', code);
  }
}
